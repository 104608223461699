import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Pricing from "../components/Pricing";
import CTA from "../components/CTA";
import Explainer from "../components/Explainer";
import SEO from "../components/SEO";
import BaseLayout from "../components/layouts/BaseLayout";
import Features from "../components/Features";
import PosterDownload from "../components/PosterDownload";

const IndexPage = () => {
  const { kanbanDone, screenshotMerkzettel } = useStaticQuery(
    graphql`
      query {
        kanbanDone: file(relativePath: { eq: "kanban-done.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
        screenshotMerkzettel: file(
          relativePath: { eq: "screenshot-merkzettel.png" }
        ) {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    `
  );

  return (
    <BaseLayout isHeroVisible={true}>
      <SEO />
      <Explainer
        title="Archive sticky notes digitally instead of discarding them"
        paragraphs={[
          "As long as a sticky note is still actively used on the board we have a great overview. But what happens as soon as the work package is done? Correct, the sticky note is thrown into the trash sooner or later.",
          "But not only the sticky note will be gone - all the team discussions at the board and all the decisions made will be discarded as well. And that's where Shotdok comes into play!",
          "Shotdok allows you to easily capture important decisions directly at the Scrum or Kanban board - forever. Each team member can use their own smartphone or tablet to add and manage entries. Just snap a picture or video of the sticky note and optionally add a voice memo or text input.",
        ]}
        image={kanbanDone.childImageSharp.gatsbyImageData}
        imageAlt="A man removes a sticky note from the done column of the Kanban board"
        isImageLeft={true}
      />
      <Explainer
        title="Reproduce decisions years later"
        paragraphs={[
          "The sticky notes that were archived using Shotdok are stored in a teams and topics structure. By using the search function the desired entry and decision is found very easily and fast. Like that, decisions can be reproduced even years later.",
          "Shotdok is designed in a way that the app fits into the agile process very smoothly: a minimalistic structure and optimized handling makes sure the app does not slow you down at all.",
          "In this screenshot of the app you can see various work packages that were captured using Shotdok. The decisions made during the process are attached to them using a voice memo or video.",
        ]}
        image={screenshotMerkzettel.childImageSharp.gatsbyImageData}
        imageAlt="Screenshot of the Shotdok app"
        isImageLeft={false}
      />
      <Features />
      <Pricing />
      <CTA />
      <PosterDownload />
    </BaseLayout>
  );
};

export default IndexPage;
